import React, { useState, useRef, useId } from "react";
import Fetcher from "../Fetch";
declare var CADESCOMSignData: any;

/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту Row для его работы. 
   @property signInfo - объект хранящий информацию и эцп пользователя
   @property rowsData  - массив документов. В данном массиве хранятся объекты документов. 
             Описание полей объекта можно увидеть в return главной функции. Данный объект есть ссылка из основного компонента Table.
             Так как нам необходимо и в основном компоненте иметь список документов и в компоненте Row. То мы проводим его из основного компонента сюда.
   @property setRowsData - ссылка на метод из основного компонента Table, обновляющего rowsData.
             Необходим нам в этом дочернем компоненте, чтобы вызвать обновление списка документов 
             и отрисовать его согласно прописанным инструкциям ниже.
   @property showInfoMessage - ссылка на метод из компонента Table. Метод показывает информационное сообщение.
   @property showErrorMessage - ссылка на метод из компонента Table. Метод показывает сообщение об ошибке.
   @property clearErrorMessage - ссылка на метод из компонента Table. Метод очищает поле сообщения об ошибке.
   @property getLotId - ссылка на метод из компонента Table. Метод возвращет поле LotId
*/
interface IPropsType {
    signInfo: {},
    rowsData: [],
    setRowsData: null,
    isEditable: boolean,
    showInfoMessage: ({ }) => void,
    showErrorMessage: ({ }) => void,
    clearErrorMessage: () => void,
    getIdValue: () => Number,
    getIdName: () => string,
    onDocSigned?: (id: number, error: string) => void
}


/**
   @function TableRows - функциональный компонет описывающий строку в таблице и содержащий логику работы с компонентами строки.
*/
function TableRows(props: IPropsType) {

/**
    @property стрелочная функция, выполняющая асинхронный запрос к серверу с данными в формате json
    @param url адрес на сервере куда должен уйти запрос
    @param data данные которые должны быть посланы. Могут быть null или в формате JSON 
    @param callback callback функция, которая будет вызвана при получении ответа от сервера для дальнейшей обработки ответа.
*/
    const fetcherPOSTJSON = (url, data, callback) => {
        Fetcher(url,
            {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                }
            );
    }

    /**
    @property стрелочная функция, выполняющая асинхронный запрос к серверу с данными обернутыми в тег form
    @param url адрес на сервере куда должен уйти запрос
    @param data данные которые должны быть посланы. Должны быть типа FormData
    @param callback callback функция, которая будет вызвана при получении ответа от сервера для дальнейшей обработки ответа.
*/

    const fetcherPOST = (url, data, callback) => {
        Fetcher(url,
            {
                method: 'POST',
                body: data
            })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                }
            )
            .catch((exception) => {
                console.log('catch: ', exception);
                callback(null);
            });
    }

    /**
       @property метод загружающий файл на сервер. Вызывается при клике по кнопке загрузить(кнопка со стрелкой вверх)
       @param index индекс элемента в массиве документов rowsData
       @param evnt событие
   */
    const sendFileToServer = (index, evnt) => {
        var rowsInput = [...props.rowsData];
        var item = rowsInput[index];
        var idValue = props.getIdValue();
        var idName = props.getIdName();

        props.clearErrorMessage();

        let isError = false;

        if (item.DocumentTitle.Value == '') {
            isError = true;
            props.showErrorMessage('Введите наименование документа');
        }

        if (item.DocumentFile.Value == null) {
            isError = true;
            props.showErrorMessage('Выберите документ');
        }

        if (!isError) {
            item.DocumentFile.Mode = 'Uploading';
            props.setRowsData(rowsInput);

            // выполним загрузку файла на сервер
            const formData = new FormData();
            formData.append("DocumentFile", item.DocumentFile.Value);
            formData.append("Comment", item.Comment.Value);
            formData.append("Title", item.DocumentTitle.Value);
            formData.append(idName, idValue.toString());

            fetcherPOST("/DocumentApi/AddDocument", formData, function (result) {
                if (result == null) {
                    props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
                    item.DocumentFile.Mode = 'Edit';
                }
                else if (result.Success) {
                    console.log('success', result);
                    item.DocumentTitle.Mode = 'View';
                    item.Comment.Mode = 'View';
                    item.DocumentFile.Mode = 'View';
                    item.DocumentId = result.UpdatedItem.docId;
                    item.Signature = result.UpdatedItem.Signature;
                    item.DataToSign = result.UpdatedItem.DataToSign;
                    item.IsAutoSave = true;

                }
                else if (result.Error) {
                    item.DocumentFile.Mode = 'Edit';
                    props.showErrorMessage(result.Error);
                }

                props.setRowsData([...rowsInput]);
            });
        }
    };

    /**
   @property метод удаляющий строку из таблице и связанный с ней документ.
   @param index индекс элемента в массиве документов rowsData
*/

    const deleteTableRows = (index) => {
        var rows = props.rowsData;
        var item = rows[index];
        props.clearErrorMessage();

        if (item.DocumentId > 0) {
            var url = "/DocumentApi/RemoveDocument?id=" + item.DocumentId;
            fetcherPOSTJSON(url, null, function (result) {
                if (result.Success) {
                    rows.splice(index, 1);
                    props.setRowsData([...rows]);
                }
                else {
                    props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
                }
            });
        }
        else {

            rows.splice(index, 1);
            props.setRowsData(rows);
        }
    };

    /**
@property предоставляюющий интерфейс для редактирования поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const editColumn = (index, nameField) => {
        var rowsInput = [...props.rowsData];
        var field = rowsInput[index][nameField];
        field.OldValue = field.Value;
        field.Mode = 'Edit';
        props.setRowsData(rowsInput);
    }

    /**
@property отвечающий за отмену изменений некоторого поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const cancelChanges = (index, nameField) => {
        var rowsInput = [...props.rowsData];
        var field = rowsInput[index][nameField];
        field.Value = field.OldValue;
        field.Mode = 'View';
        props.setRowsData(rowsInput);
    }

    /**
@property отвечающий за сохранение изменений некоторого поля строки. Отправляет новое значение на сервер для сохранения.
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const saveChanges = (index, nameField) => {
        console.log('saveChanges'); 
        var rowsInput = [...props.rowsData];
        var item = rowsInput[index];
        var field = item[nameField];
        console.log(rowsInput[index]);  

        var url = "/DocumentApi/EditDocument";
        var _data = { DoicId: item.DocumentId, Field: nameField, NewValue: field.Value };
        var _this = this;

        console.log(_data);
        console.log(JSON.stringify(_data));

        fetcherPOSTJSON(url, JSON.stringify(_data), function (result) {
            if (result.Success) {
                console.log('success');
                field.Mode = 'View';
                props.setRowsData(rowsInput);
                props.showInfoMessage('Изменения успешно сохранены');
            }
            else if (result.Error) {
                console.log('error: ', result.Error);
                props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
            }
        });
    }

    /**
@property отвечающий за измнения в текстовом поле и проводящий эти изменения в модель. Вызвается по событию onChange
@param index индекс элемента в массиве документов rowsData
@param evnt событие
*/
    const handleChange = (index, evnt) => {
        let data = evnt.target.name.split('_');
        let name = data[0];

        const rowsInput = [...props.rowsData];
        rowsInput[index][name].Value = evnt.target.value;
        props.setRowsData(rowsInput);
    };

    /**
@property реагирующий на выбор файла с компьютера пользователем. И проводящий эти изменения в модель. Вызвается по событию onChange у <input type=field>
@param index индекс элемента в массиве документов rowsData
@param evnt событие
*/
    const handleFileChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const file = evnt.target.files?.[0];
        const rowsInput = [...props.rowsData];
        rowsInput[index].DocumentFileName = file.name;
        rowsInput[index].DocumentFile.Value = file;
        props.setRowsData(rowsInput);
    };

    /**
@property обрабатывающий нажатие на ссылку Подписать документ. 
Получает информацию о документе из скрытых полей и проводит её в CADESCOMSignData
который непосредственно подписывает документ эцп пользователя.
@param index индекс элемента в массиве документов rowsData
*/
    const handleSignProtocol = (index) => {
        const rowsInput = [...props.rowsData];
        var doc = rowsInput[index];

        CADESCOMSignData(doc.DataToSign,
            doc.DataToSign,
            props.signInfo.Thumbprint,
            true,
            doc.Signature,
            true,
            function (signInfo) {

                Fetcher("/DocumentApi/AddSignature",
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ SignInfo: signInfo, DocId: doc.DocumentId })
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.Success) {
                                doc.IsSigned = true;
                                doc.SignStatusDescription = 'Подписан';
                                props.setRowsData(rowsInput);
                            }
                            else
                                props.showErrorMessage(result.Error);

                            if (props.onDocSigned)
                                props.onDocSigned(doc.DocumentId, result.Error);
                        },
                        (error) => {
                            props.showErrorMessage(error);

                            if (props.onDocSigned)
                                props.onDocSigned(doc.DocumentId, result.Error);
                        }
                    );
            }
        );
    }

    /**
@property возвращающий набор кнопок для редактирования некоторого поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const buttonsActions = (index, nameField) => {
        const rowsInput = [...props.rowsData];
        const field = rowsInput[index][nameField];

        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"
                    style={{ display: field.Mode == 'View' ? "inline" : "none" }}
                    onClick={() => { editColumn(index, nameField); }}
                >
                    <path d="M12.424 0.576312C12.0544 0.207275 11.5534 0 11.0311 0C10.5089 0 10.0079 0.207275 9.63832 0.576312L0.796451 9.41837C0.543248 9.67015 0.342485 9.96965 0.205778 10.2995C0.0690698 10.6294 -0.000868735 10.9831 8.1445e-06 11.3402V12.4563C8.1445e-06 12.6005 0.0572851 12.7388 0.159239 12.8408C0.261192 12.9427 0.399471 13 0.543655 13H1.65976C2.01681 13.001 2.37051 12.9312 2.70039 12.7946C3.03026 12.6579 3.32977 12.4572 3.58155 12.2041L12.424 3.36147C12.7928 2.99189 13 2.49106 13 1.96889C13 1.44673 12.7928 0.945894 12.424 0.576312ZM2.81284 11.4353C2.50622 11.7399 2.09194 11.9114 1.65976 11.9127H1.0873V11.3402C1.08675 11.1259 1.1287 10.9137 1.21073 10.7158C1.29276 10.5179 1.41323 10.3382 1.56517 10.1871L8.2754 3.47673L9.52579 4.72714L2.81284 11.4353ZM11.6547 2.59274L10.2923 3.95569L9.04194 2.708L10.4049 1.34504C10.487 1.26312 10.5844 1.19817 10.6916 1.1539C10.7988 1.10963 10.9137 1.08691 11.0297 1.08704C11.1456 1.08716 11.2605 1.11013 11.3676 1.15464C11.4747 1.19914 11.572 1.2643 11.6539 1.3464C11.7358 1.42851 11.8008 1.52594 11.845 1.63315C11.8893 1.74035 11.912 1.85523 11.9119 1.97121C11.9118 2.0872 11.8888 2.20202 11.8443 2.30913C11.7998 2.41624 11.7346 2.51353 11.6525 2.59546L11.6547 2.59274Z" fill="#690097" />
                </svg>
                <img src="/Content/images/fl_okey.png" alt="Сохранить изменения"
                    style={{ display: field.Mode == 'Edit' ? "inline" : "none" }}
                    onClick={() => {
                        saveChanges(index, nameField);
                    }}
                />
                <img src="/Content/images/fl_cancel.png" alt="Отменить изменения"
                    style={{ display: field.Mode == 'Edit' ? "inline" : "none" }}
                    onClick={() => { cancelChanges(index, nameField); }}
                />
            </>
        );
    }

    return (

        props.rowsData.map((data, index) => {
            const 
                {
                    DocumentId,
                    DocumentTitle,
                    Comment,
                    DocumentFile,
                    DisplayNumber,
                    DocumentFileName,
                    IsAutoSave,
                    IsError,
                    IsSigned,
                    SignStatusDescription,
                    Signature,
                    DataToSign,
                    DocumentLink
                } = data;

            return (

                 <tr key={DisplayNumber}>
                    <td>
                        {DocumentTitle.Mode == 'Edit' ?
                            <div class="actions">
                                <input type="text" name={'DocumentTitle_' + DisplayNumber}
                                    className="defaultInput"
                                    value={DocumentTitle.Value}
                                    onChange={(evnt) => (handleChange(index, evnt))}
                                />
                                {IsAutoSave && props.isEditable && buttonsActions(index, 'DocumentTitle')}
                            </div>
                            :
                            <div class="actions">
                                <label>{DocumentTitle.Value}</label>
                                {props.isEditable && buttonsActions(index, 'DocumentTitle')}
                            </div>
                         }
                    </td>
                    <td>
                        {Comment.Mode == 'Edit' ?
                            <div class="actions">
                                <input name={'Comment_' + DisplayNumber} className="defaultInput"  
                                    value={Comment.Value}
                                    rows={10} required onChange={(evnt) => (handleChange(index, evnt))}>
                                </input>
                                {props.isEditable && IsAutoSave && buttonsActions(index, 'Comment')}
                            </div>
                            :
                            <div class="actions">
                                <label>{Comment.Value}</label>
                                {props.isEditable && buttonsActions(index, 'Comment')}
                            </div>
                            }
                    </td>
                    <td>
                        {DocumentFile.Mode != 'View' ?
                            <div className="uploadInput">
                                <label htmlFor={"file" + index}>
                                    <input id={"file" + index} type="file" style={{ display: 'none' }}
                                        onChange={(evnt) => (handleFileChange(index, evnt))} />
                                    {DocumentFileName ? DocumentFileName : 'Выбрать файл '}
                                </label> 
                            </div>
                            :
                            <div class="actions">
                                <label className=""><a href={DocumentLink}>Скачать</a></label>                              
                            </div>
                        }
                        {props.signInfo.CurrentUserCanSign && <div className="signData">
                            <input type="hidden" id={"hdnSignInfo_" + index} value={DataToSign} />
                            <input type="hidden" id={"hdnExistingSignData_" + index} value={Signature} />
                        </div>}
                            
                    </td>
                    <td>
                        <div className="sign-container">
                            {IsSigned ?
                                (<span> {SignStatusDescription} </span>) :
                                (props.isEditable && props.signInfo.CurrentUserCanSign && DataToSign
                                    ? <button onClick={(e) => { handleSignProtocol(index) }} title="Подписать"> Подписать</button>
                                    : (DocumentFile.Value ? <span>не подписан</span>
                                                          : ''))
                            }
                        </div>

                    
                    </td>
                    {props.isEditable &&
                        <td class="action">
                        <div className="actionButtons">
                            <button className="deleteButton" onClick={() => (deleteTableRows(index))}>x</button>
                            <button className="uploadButton" onClick={(e) => (sendFileToServer(index, e))}
                                style={{ padding: '7px', display: DocumentFile.Mode != 'View' ? 'flex' : 'none' }}>
                                {DocumentFile.Mode == 'Uploading'
                                    ? (<img src="/Content/images/loading_sm.gif" style={{ width: '10px' }} />)
                                    : (<img src="/Content/images/upload.png" alt="Загрузить файл на сервер" />)}
                            </button>
                        </div>
                    </td>
                        }
                 </tr>
            )
        })

    )

}
export default TableRows;