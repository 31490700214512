import React, { useState, useEffect } from "react";


function LotContacts() {




    return (
        <div className="lotContacts detailsWrapper">
            <div className="contactItem">
                <span className="defaultDataName">Телефон горячей линии:</span>
                <a href="tel:74951473765" className="defaultDataValue">+7 (495) 147-37-65</a>
            </div>

            <div className="contactItem">
                <span className="defaultDataName">Электронная почта:</span>
                <a href="mailto:support@viomitra.ru" className="defaultDataValue">support@viomitra.ru</a>
            </div>
        </div>
    )
}


export default LotContacts;