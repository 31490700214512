import React, { useState, useEffect } from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';



interface IPropsType {
    address: string
}
function YandexMap(props: IPropsType) {
    const [coordinates, setCoordinates] = useState(null); // Состояние для координат

    useEffect(() => {
        if (props.address) {
            const fetchCoordinates = async () => {
                const ymaps = await window.ymaps.ready(); // Дождаться загрузки API
                const result = await ymaps.geocode(props.address); // Геокодирование адреса
                const firstGeoObject = result.geoObjects.get(0); // Получение первого найденного объекта
                if (firstGeoObject) {
                    const coords = firstGeoObject.geometry.getCoordinates(); // Координаты объекта
                    setCoordinates(coords);
                }
            };
            fetchCoordinates();
        }
    }, [props.address]);

    return (
        <YMaps query={{ apikey: '27610715-1b8a-485b-a88a-671b5888d486' }}>
            {coordinates ? (
                <Map
                    defaultState={{
                        center: coordinates, // Центр карты
                        zoom: 10, // Уровень приближения
                        controls: ['zoomControl', 'fullscreenControl'], // Элементы управления
                    }}
                    width="100%"
                    height="100%"
                    modules={['control.ZoomControl', 'control.FullscreenControl']} // Подключение модулей
                >
                    <Placemark geometry={coordinates} /> {/* Метка на карте */}
                </Map>
            ) : (
                <p>Загрузка карты...</p>
            )}
        </YMaps>
    );
};

export default YandexMap;