import React, { useState, useEffect } from "react";
import Fetcher from "../Common/Fetch";
import { IDimensions, IDeliveryStartPoint } from "../Common/types";
import { method } from "lodash";

interface IPropsType {
    lotId: number;
}

function CDEKWidget(props: IPropsType) {
    const [token, setToken] = useState<string | null>(null);
    const [dimensions, setDimensions] = useState<IDimensions | null>(null);
    const [deliveryStartPoint, setDeliveryStartPoint] = useState<IDeliveryStartPoint | null>(null);

    // Получение данных лота
    useEffect(() => {
        const fetchLotData = async () => {
            try {
                const response = await Fetcher(`/TradeEditApi/GetDeliveryDetails?lotId=${props.lotId}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });

                if (response.ok) {
                    const data = await response.json();
                    const dimensionsObject = typeof data.UpdatedItem === "string" ? JSON.parse(data.UpdatedItem) : null;

                    if (dimensionsObject) {
                        setDimensions({
                            Width: dimensionsObject.Width,
                            Height: dimensionsObject.Height,
                            Length: dimensionsObject.Length,
                            Weight: dimensionsObject.Weight,
                        });
                        setDeliveryStartPoint({
                            City: dimensionsObject.FromCity,
                            Address: dimensionsObject.FromAddress,
                            CountryCode: "RU",
                        });
                    }
                }
            } catch (error) {
                console.error("Ошибка загрузки данных лота:", error);
            }
        };

        fetchLotData();
    }, [props.lotId]);

    // Инициализация виджета
    useEffect(() => {
        const initializeCDEKWidget = async (userLocation = null) => {
            const CDEKWidget = (window as any).CDEKWidget;

            if (CDEKWidget && dimensions && deliveryStartPoint) {
                const widget = new CDEKWidget({
                    from: {
                        country_code: deliveryStartPoint.CountryCode,
                        city: deliveryStartPoint.City,
                        address: deliveryStartPoint.Address,
                    },
                    to: userLocation
                        ? { latitude: userLocation.lat, longitude: userLocation.lng }
                        : undefined,
                    goods: [
                        {
                            width: dimensions.Width,
                            height: dimensions.Height,
                            length: dimensions.Length,
                            weight: dimensions.Weight,
                        },
                    ],
                    servicePath: '/Cdek/ProcessRequest',
                    root: "cdek-map",
                    apiKey: "49ea5682-b2de-4233-86e3-e009cff7dfb4",
                    debug: true,
                    defaultLocation: userLocation
                        ? [userLocation.lng, userLocation.lat] // Координаты пользователя
                        : "Россия", // Запасной вариант — текстовое название страны
                    lang: "rus",
                    currency: "RUB",
                    onReady: () => console.log("Виджет готов"),
                    onChoose: (type, tariff, address) => {
                        console.log('Выбран метод доставки', type, tariff, address);

                        if (type === 'door') {
                            console.log(
                                'Выбрана доставка курьером по адресу\n' +
                                address.formatted +
                                '\n' +
                                'Цена ' + tariff.delivery_sum + '\n' +
                                'Срок ' + tariff.period_max + ' дн.',
                            );
                        } else {
                            console.log(
                                'Выбран пункт выдачи заказа ' + address.name + '\n' +
                                'Цена ' + tariff.delivery_sum + '\n' +
                                'Срок ' + tariff.period_max + ' дн.',
                            );
                        }
                    },
                    onCalculate: (tariffs, address) => {
                        console.log('Подсчитаны тарифы', tariffs);
                        console.log('Адрес подсчета', address);
                    },
                    onError: (error) => {
                        console.error('Ошибка в CDEK Widget:', error);
                    },
                });
            }
        };

        if (dimensions && deliveryStartPoint) {
            // Попробуем получить местоположение пользователя
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    initializeCDEKWidget(userLocation);
                },
                () => {
                    // Если местоположение недоступно, используем запасной вариант
                    initializeCDEKWidget(null);
                }
            );
        }
    }, [dimensions, deliveryStartPoint]);

    return <div id="cdek-map"></div>;
}

export default CDEKWidget;