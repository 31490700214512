import React, { useState, useEffect } from "react";
import fetcher from "../../Common/Fetch";
import { ISellerInfo, ILotModel } from "../../Common/types";
import { fetcherPOSTJSON } from "../../Common/Fetch";

interface IPropsType {
    sellerInfo: ISellerInfo,
    lotDetails: ILotModel,
    lotId: number
}

function LotSaler(props: IPropsType) {

    const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);

    const Subscribe = async() => {
        const response = await fetch("/TradeLotActionsApi/SubscribeToCompany?id=" + props.sellerInfo.CompanyId, {
            method: "POST",
            headers: { "Accept": "application/json" },
        });
        if (response.ok) {
            let result = await response.json();
            let succesResult = result.Success;
            if (succesResult) {
                console.log('subscribe complete');
                props.lotDetails.IsSubscribed = true;
                setIsSubscribeOpen(false);
            } else {
                console.log('not find users');
            }
        } else {
            console.log('error request');
        }
    }
    

    return (
        <>
            <div className="aboutSaler detailsWrapper">
                <div className="saler">
                    <span className="defaultDataName">Контактное лицо:</span>
                    <p className="defaultDataValue">{props.sellerInfo.CompanyName}</p>
                </div>

                <div className="actionButtons">
                    {/*<a className="defaultPinkBorderButton">Все лоты продавца</a>*/}
                    {!props.lotDetails.IsOrganizer ? (
                        props.lotDetails.IsSubscribed ? (
                            <p className="defaultDescription">Вы подписаны на новинки</p>
                        ) : (
                            <button className="defaultBlackBorderButton subscribe" onClick={() => { setIsSubscribeOpen(true) }}>Подписаться на продавца</button>
                        )
                    ) : (null)}
                </div>

                


                {isSubscribeOpen &&
                    <div className="defaultPopupWrapper">
                        <div className="defaultPopup">
                            <button className="closePopupButton" onClick={() => { setIsSubscribeOpen(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                                </svg>
                            </button>

                            <div className="popupTitle">
                                <h4>Подписаться на продавца? <hr /></h4>
                            </div>

                            <button className="defaultPinkButton" onClick={Subscribe}>Подписаться</button>
                        </div>
                    </div>
                }
            </div>

            
        </>
    )
}


export default LotSaler;