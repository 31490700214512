import React, { useState, useEffect } from "react";
import { ILotFilterValueModel } from "../../Common/types";
import { IDocumentModel } from "../../Common/types";

interface ILotFilter {
    FilterName: string,
    Value: String
}

interface IPropsType {
    lotFilters: Array<ILotFilter>,
    lotDescription: string,
    documents: Array<IDocumentModel>
}
function AboutLot(props: IPropsType) {

    const [data, setData] = useState(props.lotFilters['Основные характеристики']);

    const [lotDescription, setLotDescription] = useState('');
    const [splitedArr, setSplitedArr] = useState([]);
    const [isShowMoreButton, setIsShowMoreButton] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);

    useEffect(() => {
        if (props.lotDescription) {
            const splitDescription = props.lotDescription.split(' ');
            if (splitDescription.length > 40) {
                setSplitedArr(splitDescription);
                setIsShowMoreButton(true);
                setLotDescription(splitDescription.slice(0, 40).join(' '));
            }
        }
    }, [])

    const showMore = () => {
        if (isShowMore) {
            setLotDescription(splitedArr.slice(0, 40).join(' '));
            setIsShowMore(false);
        } else {
            setLotDescription(splitedArr.join(' '));
            setIsShowMore(true);
        }
    }

    return (
        <div className="aboutLot detailsWrapper">
            <div className="dataTable">
                {data ? (
                    data.map((item) => {
                        return (
                            <div className="dataItem">
                                <span className="defaultDataName">{item.FilterName}:</span>
                                <p className="defaultDataValue">{item.Value}</p>
                            </div>
                        )
                    })
                ) : (
                    <p className="errorText">Данные отсутствуют</p>
                )}
            </div>

            <div className="documents">
                <h4 className="defaultDataTitle">Сертификаты и документы</h4>

                {(props.documents && props.documents.length >= 1) ? (
                    props.documents.map(doc => {
                        return (
                            <div key={doc.DocumentId} className="documentItem">
                                <span className="defaultDataName">{doc.Name}:</span>
                                <img src="../../main-images/agreementIcon.svg" />
                                <div className="documentInfo">
                                    <a href={doc.DocumentLink}>{doc.Name}</a>
                                    {/*<a>Подписано: Кем-то подписано</a>*/}
                                </div>
                                <p>({
                                    new Date(doc.CreationDate).toLocaleString('en-US', {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: false
                                    }).replace(/\//g, '.').replace(',', '')
                                })</p>
                            </div>
                        )
                    })
                ) : (
                    <p className="errorText">Документы отсутствуют</p>
                )}
            </div>
        </div>
    )
}


export default AboutLot;