import React, { useState, useEffect } from "react";
import Fetcher from "../Fetch";
import { IDocumentModel } from "../types";
declare var CADESCOMSignData: any;

interface IProps{
    Doc: IDocumentModel;
    onGenerate: (callback) => void;
    onSigned: (callback) => void;
    Thumbprint: string
}

function GenerateAndSign(props: IProps) {
    const [firstTime, setFirstTime] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)

    const generateDoc = () => {
        setIsLoading(true);
        props.onGenerate((error) => { setIsLoading(false); setError(error); })
    }

    const handleSignDoc = () => {
        var doc = props.Doc
        setIsLoading(true);

        CADESCOMSignData(doc.DataToSign,
            doc.DataToSign,
            props.Thumbprint,
            true,
            doc.Signature,
            true,
            function (signInfo) {

                Fetcher("/DocumentApi/AddSignature",
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ SignInfo: signInfo, DocId: doc.DocumentId })
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.Success) {
                                props.onSigned(() => { setIsLoading(false); });
                            }
                            else {
                                setError(result.Error)
                                setIsLoading(false);
                            }

                            
                            
                        },
                        (error) => {
                            setError(error)
                            setIsLoading(false);
                        }
                    );
            }
        );
    }

    return (
        <React.Fragment>
            {!props.Doc &&
                <button className="defaultPinkButton" onClick={(e) => { generateDoc(); }}>
                    {isLoading ? (
                        <img
                            src="/Content/images/loading_sm.gif"
                            style={{ width: "20px" }}
                        />
                    ) : "Сформировать протокол"}
                </button>
            }
            {props.Doc && 
                <div className="doc-container">
                    <span className="doc-name"><a href={props.Doc.DocumentLink}>{props.Doc.Name}</a></span>
                    {props.Doc.IsSigned ?
                        (<span> {props.Doc.SignStatusDescription} </span>) :
                        (props.Doc.DataToSign
                            ? <a role="button" onClick={(e) => { handleSignDoc() }} title="Подписать"> Подписать</a>
                            : '')
                    }
                </div>}
        </React.Fragment>
    );
}


export default GenerateAndSign;