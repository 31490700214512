import React, { useState, useEffect } from "react";
import { IAboutTrade } from "./LotPage";
import { IDocumentModel } from "../../Common/types";
import { ApplicationAcceptance } from "../../TradeApplication/components/ApplicationAcceptance";
import LotWinnerProtocol from "../Document/LotWinnerProtocol";

interface IPropsType {
    aboutTrade: IAboutTrade,
    documents: Array<IDocumentModel>
    lotId: number,
    allowProcessing: boolean,
    allowFinish: boolean,
    lotDescription: string
}

//interface IAboutTrade {
//    LotState: string,
//    TradeType: string,
//    DepositRules: string,
//    LotTradePeriodStart: string,
//    LotTradePeriodEnd: string
//}

function AboutTrade(props: IPropsType) {
    const [lotDescription, setLotDescription] = useState('');
    const [splitedArr, setSplitedArr] = useState([]);
    const [isShowMoreButton, setIsShowMoreButton] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);

    useEffect(() => {
        if (props.lotDescription) {
            const splitDescription = props.lotDescription.split(' ');
            const filter = splitDescription.filter(i => i !== '');
            if (filter.length > 90) {
                setSplitedArr(filter);
                setIsShowMoreButton(true);
                setLotDescription(filter.slice(0, 90).join(' '));
            }
        }
    }, [])

    useEffect(() => {console.log(lotDescription)}, [lotDescription])

    const showMore = () => {
        if (isShowMore) {
            setLotDescription(splitedArr.slice(0, 90).join(' '));
            setIsShowMore(false);
        } else {
            setLotDescription(splitedArr.join(' '));
            setIsShowMore(true);
        }
    }


    return (
        <div className="aboutTrade detailsWrapper">
            <div className="description">
                <div className="descriptionText">
                    {/*<p>{props.lotDescription}</p>*/}
                    <p>
                        {lotDescription}
                    </p>
                    {isShowMoreButton &&
                        <span onClick={showMore}>
                            {isShowMore ? 'Скрыть' : 'Показать больше'}
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}


export default AboutTrade;