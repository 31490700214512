import React, { useEffect, useState } from 'react'
import { GetTradeApplicationAcceptancePromise, TradeApplicationChangeStatus, GeneratePartyDesicionProtocol, GetPartyDesicionProtocol, FinishPartyDesicion } from "../remote";
import { useDispatch, useSelector } from 'react-redux'
import { setApplication, setLoading, setLoadingFinish, setConfirm } from "../applicationSlice";
import { ITradeApplication, ApplicationStatusses, IAcceptanceModel, IAppChangeStatusModel } from '../types';
import { DATE_TIME_FORMAT } from "../../settings";
import moment from "moment";
import $ from "jquery";
import AddDeleteTableRows from "../../Common/Table/Table";
import GenerateAndSign from "../../Common/Document/GenerateAndSign"
import { Checkbox } from "../../Common/Form";
import CheckBoxList from "../../Common/Form/CheckboxList";
import TextArea from "../../Common/Form/TextArea";
import {
    ISelectListItem,
    IDocumentModel
} from "../../Common/types";

interface IPropsType {
    lotId: Number
}

export function ApplicationAcceptance({ lotId }: IPropsType) {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const [firstTime, setFirstTime] = useState(true);
    const [model, setModel] = useState<IAcceptanceModel>(null);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [protocol, setProtocol] = useState<IDocumentModel>(null)
    const [thumbprint, setThumbprint] = useState<string>();
    const [selectedApp, setSelectedApp] = useState<ITradeApplication>(null);
    const availableOptions: Array<ISelectListItem> = [{ Text: "Принять", Value: ApplicationStatusses.Approved.toString(), Selected: true, Disabled: false },
        { Text: "Отклонить", Value: ApplicationStatusses.Declined.toString(), Selected: false, Disabled: false }];

    const [acceptState, setAcceptState] = useState(null);
    const [acceptMessage, setAcceptMessage] = useState(null);

    useEffect(() => {
        if (firstTime) {
            GetTradeApplicationAcceptancePromise(lotId).then((result) => {
                if (result.Success) {
                    setModel(result.UpdatedItem);
                    setProtocol(result.UpdatedItem.Protocol);
                    setThumbprint(result.UpdatedItem.Thumbprint)
                }
            });
        }

        setFirstTime(false);
    }, []);

    const setAppStatus = () => {
        console.log(acceptState);
        console.log(acceptMessage);
        setIsLoading(true);

        if (acceptState == ApplicationStatusses.Declined && acceptMessage !== '' && acceptMessage !== null) {
            changeStatus();
        } else {
            setError('Заполните причину отказа')
            setIsLoading(false);
        }

        if (acceptState == ApplicationStatusses.Approved) {
            changeStatus();
        }
    }

    const changeStatus = () => {
        let model: IAppChangeStatusModel = { Id: selectedApp.Id, Reason: acceptMessage, Status: acceptState };

        TradeApplicationChangeStatus(model).then((result) => {
            if (result.Success) {
                setModel(result.UpdatedItem);
                setProtocol(result.UpdatedItem.Protocol);
                setThumbprint(result.UpdatedItem.Thumbprint)
                showHidePopup(null, 'hide');
            }
            else {
                setError(result.Error);
            }

            setIsLoading(false);
            setError('');
        });
    }

    const showHidePopup = (app, action) => {
        setAcceptState(null);
        setAcceptMessage(null);
        setSelectedApp(app);
        setIsOpen2(true);
    }

    const onGenerateProtocol = (callback) => {
        GeneratePartyDesicionProtocol(lotId).then((result) => {
            if (result.Success) {
                setProtocol(result.UpdatedItem);
                callback(null)
            }
            else {
                callback(result.Error)
            }
        });
    }

    const onSignProtocol = (callback) => {
        FinishPartyDesicion(lotId).then((result) => {
            window.location.reload();
        });
    }

    return (

        <>
            <button className="defaultPinkButton" onClick={() => {setIsOpen(true) }}>Посмотреть заявки</button>

            {isOpen &&
                <div className="defaultPopupWrapper">
                    <div className="defaultPopup acceptancePopup">
                        <button className="closePopupButton" onClick={() => { setIsOpen(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                            </svg>
                        </button>

                        <div className="popupTitle">
                            <h4>Заявки <hr /> </h4>
                        </div>
                        {model && model.Applications && model.Applications.length > 0 ?
                            (
                                <div className="acceptanceTable">
                                    <table className="defaultTable">
                                        <thead>
                                            <tr>
                                                <th>Участник</th>
                                                <th>Дата</th>
                                                {model.ShowBets &&
                                                    <th style={{ width: '20%' }}>Ставка</th>
                                                }
                                                <th>Статус</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {model.Applications.map(app =>
                                                <tr>

                                                    <td><a role="button" onClick={(e) => { showHidePopup(app, 'show') }} title={app.ParticipantName}>
                                                        {app.ParticipantName}
                                                    </a></td>
                                                    <td>{moment(app.ApplyDate).format('DD.MM.YYYY hh.mm.ss')}</td>
                                                    {model.ShowBets &&
                                                        <td>{app.Bet}</td>
                                                    }
                                                    <td>
                                                        <span>{app.StatusName}</span>
                                                    </td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="errorText">Заявок нет</p>
                            )
                        }

                        {model && model.AllowProtocolGeneration &&

                            <div className="protocol-container" style={{ width: '100%' }}>
                                <GenerateAndSign Doc={protocol} onGenerate={onGenerateProtocol} onSigned={onSignProtocol} Thumbprint={thumbprint}></GenerateAndSign>
                            </div>
                        }
                    </div>
                </div>
            }

            {selectedApp && selectedApp.Id != 0 && isOpen2 &&
                <React.Fragment>
                    <div className="defaultPopupWrapper">
                        <div className="defaultPopup acceptancePopup">
                            <button className="closePopupButton" onClick={() => { setIsOpen2(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                                </svg>
                            </button>

                            <div className="popupTitle">
                                <h4>Заявка от {selectedApp.ParticipantName} <hr /> </h4>
                                <p>
                                    Добавлено: {moment(selectedApp.ApplyDate).format(DATE_TIME_FORMAT)}
                                    <br />
                                    Статус: {selectedApp.StatusName}
                                </p>
                            </div>

                            <div className="acceptanceTable">
                                <AddDeleteTableRows
                                    idValue={selectedApp.Id}
                                    idProperty="TradeApplicationId"
                                    data={selectedApp.Documents}
                                    prefix="fl"
                                    onAddDocument={() => { }}
                                    isEditable={false}
                                    onDocSigned={(id, error) => { }}
                                />
                            </div>

                            {(selectedApp.StatusId == ApplicationStatusses.WaitingForConfirmation) &&
                                <React.Fragment>
                                    {/*<CheckBoxList*/}
                                    {/*    allowMultiple={false}*/}
                                    {/*    checkBoxAdditionalClass={"mb-2"}*/}
                                    {/*    items={availableOptions}*/}
                                    {/*    name={"TradeApplicationStatusChange"}*/}
                                    {/*    disabled={false}*/}
                                    {/*    onChange={(s, add) => {*/}
                                    {/*        if (add) {*/}
                                    {/*            let state = parseInt(s.Value);*/}

                                    {/*            if (state == ApplicationStatusses.Approved)*/}
                                    {/*                setAcceptMessage('');*/}

                                    {/*            setAcceptState(parseInt(s.Value));*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    showValidation={false}*/}
                                    {/*    rowCount={1}*/}
                                    {/*    selectedItems={[acceptState]}*/}
                                    {/*/>*/}

                                    <div className="checkboxRow">
                                        <div className='customCheckboxWrapper'>
                                            <div
                                                className={acceptState == ApplicationStatusses.Approved ? 'customCheckboxActive' : 'customCheckbox'}
                                                onClick={() => {
                                                    setAcceptState(ApplicationStatusses.Approved)
                                                }}
                                            ></div>
                                            <p>
                                                Принять
                                            </p>
                                        </div>

                                        <div className='customCheckboxWrapper'>
                                            <div
                                                className={acceptState == ApplicationStatusses.Declined ? 'customCheckboxActive' : 'customCheckbox'}
                                                onClick={() => {
                                                    setAcceptState(ApplicationStatusses.Declined)
                                                }}
                                            ></div>
                                            <p>
                                                Отклонить
                                            </p>
                                        </div>
                                    </div>

                                    {acceptState == ApplicationStatusses.Declined &&

                                        <textarea className="defaultInput" placeholder="Причина отказа" onBlur={(e) => {setAcceptMessage(e.target.value)}} />

                                        //<TextArea
                                        //    label={"Причина отказа"}
                                        //    name={"DeclineReason"}
                                        //    value={acceptMessage}
                                        //    onChange={(e) => { }}
                                        //    required={acceptState == ApplicationStatusses.Declined}
                                        //    placeholder="Введите текст"
                                        //    removeValidationMsg={true}
                                        //    errorMessage={""}
                                        //    columnClass={"col-md-7 gutter-col-10"}
                                        //    onBlur={(e) => {
                                        //        setAcceptMessage(e.target.value);
                                        //    }}
                                        ///>
                                    }

                                    {error && <p className={"errorText"}>{error}</p>}
                                    <button className="defaultPinkButton" onClick={(e) => { setAppStatus(); }}>
                                        {isLoading ? (
                                            <img
                                                src="/Content/images/loading_sm.gif"
                                                style={{ width: "20px" }}
                                            />
                                        ) : "Рассмотреть Заявку"}
                                    </button>
                                </React.Fragment>}

                        </div>
                    </div>
                </React.Fragment>
            }

            
        </>
	);
}