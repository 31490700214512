/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import * as React from 'react';
import TextInput from "../../Common/Form/TextInput";
import { ISelectListItem, IPurchasedService } from '../../Common/types';
import Fetcher from "../../Common/Fetch"
import CustomSelect from "../../Common/Form/CustomSelect";

import { fetcherPOSTJSON } from "../../Common/Fetch";



interface IPropsType {
    Balance: number;
    ListServices: [];
    PurchasedServices: [];
    PurchasedLotApplicationPrices: [];
    CompanyId: number;
    Amount: number;
    AccountId: number;
    ActiveServiceName: string;
    ActiveOrderId;
    ProjectsDictionary: Record<number, string>;
}

interface IServiceType {
    Id: number,
    Name: string,
    ValidityPeriodDays: number,
    Price: number
}

function MyBalance(props: IPropsType) {  

    const [activeOption, setActiveOption] = useState<string>();
    const [activeOption2, setActiveOption2] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpen2, setIsOpen2] = useState<boolean>(false);

    const [purchasedServices, SetPurchardServices] = useState(props.PurchasedServices);
    const [purchasedLot, SetPurcharsedLot] = useState(props.PurchasedLotApplicationPrices);
    const [selectedOption, setSelectedOption] = useState(props.ListServices[0].Value)
    const [selectedProject, setSelectedProject] = useState(Object.keys(props.ProjectsDictionary)[0])
    const [lotNum, setLotNum] = useState("")

    const [tarifInfo, setTarifInfo] = useState<IServiceType | null>(null);
    const [servicesList, setServicesList] = useState([]);

    const handleLotNumChange = (event) => {
        setLotNum(event.target.value);
    };

    const handleSelectChange = (value) => {

        setTarifInfo(value);
        console.log(value)
    };

    const getServices = async () => {
        try {
            const response = await Fetcher("/ServiceApi/GetAllServices", {
                method: "GET",
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            console.log(data.UpdatedItem)
            setServicesList(data.UpdatedItem);
        } catch (error) {
            console.error("Failed Services:", error);
        }
    };

    useEffect(() => {
        getServices();
    }, [])


    const makeTarifPayment = () => {
        if (tarifInfo) {
            Fetcher(`/Profile/GetBillPaymentServicePDF?companyId=${props.CompanyId}&serviceId=${tarifInfo.Id}`, {
                method: 'GET'
            })
                .then(res => res.blob())
                .then(result => {
                    console.log("Результат fetch " + result);
                    const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
                    window.open(url, '_blank');
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Счет_на_оплату.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }
        
    }


    const handleSelectProject = (value) => {
        setSelectedProject(value)
    }

    const GetBillMakingDepositPdf = async (companyId: number, lotnum: number) => {
        // проверим для укзанного номера лота есть ли у него возможность вносить обеспечительный платеж
        Fetcher('/ProfileApi/CheckLotDeposit?lotId=' + lotnum,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        const url = new URL(`/Profile/GetBillMakingDepositPdf?companyId=${props.CompanyId}&lotId=${lotnum}`, window.location.origin);
                        console.log("Результат fetch " + result);
                        window.open(url.toString(), '_blank');
                        //const link = document.createElement('a');
                        //link.setAttribute('download', 'Счет_на_оплату.pdf');
                        //document.body.appendChild(link);
                        //link.click();
                        //link.parentNode.removeChild(link);
                    }
                    else {
                        console.log(result);
                        alert(result.Error);
                    }
                }
            );
    }

    const GetPurchasedServices = async (accountId:number) => {
        const responce = await fetch("/OrderServiceApi/GetPurchasedServicesByAccountId",
            {
                method: "GET",
                headers: {
                    "accountId": accountId
                }
            })
        const data = responce.json();
        return data;
    }

    const CreateLotApplicationPrice = async (companyId: number,lotId:number) => {
        const responce = await fetch("/LotApplicationPriceApi/CreateLotApplicationPrice",
            {
                method: "POST",
                headers: {
                    "companyId": companyId,
                    "lotId": lotId

                }
            })
    }

    const GetLotApplicationPricesByCompanyId = async (companyId: number) => {
        const responce = await fetch("/LotApplicationPriceApi/GetLotApplicationPricesByCompanyId",
            {
                method: "GET",
                headers: {
                    "companyId": companyId.toString(),
                    "Content-Type": "application/json"
                }
            })
        const data = await responce.json()
        console.log("Fetched lot application prices:", data)
        return data
    }

    const GetServicePrice = async (id: number) => {
        const responce = await fetch("/ServiceApi/GetServiceById",
            {
            method: "GET",
            headers: {
                "id": id
            }
        })
        const data = await responce.json();
        return data.UpdatedItem.Price
    }

    const SwitchServiceProject = async (orderId: number, projectId: number) => {
        const responce = await fetch("/OrderServiceApi/ChangeOrderProject",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "orderId": orderId.toString(),
                    "projectId": projectId.toString()
                }
            })
        return responce
    }

    const AddOrder = async () => {
        const priceService = await GetServicePrice(selectedOption)
        const responce = await fetch("/OrderServiceApi/CreateOrder",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            body: JSON.stringify(
            {
                "orderServiceModel": {
                    "price": priceService,
                    "accountId": props.AccountId
                },
                "idServices": [selectedOption]
            })
        })
    }

    const RedirectToTradeLot = async (lotId) => {
        window.location.href = `/TradeLot/View?id=${lotId}`;
    }

    const handleMakingDeposit = async () => {
        await CreateLotApplicationPrice(props.CompanyId, lotNum)
        const purchasedLotApplicationPrices = await GetLotApplicationPricesByCompanyId(props.CompanyId)
        SetPurcharsedLot(purchasedLotApplicationPrices.UpdatedItem)
        await GetBillMakingDepositPdf(props.CompanyId, lotNum)
    }

    const handleSwitchServiceProject = async () => {
        const responce = await SwitchServiceProject(props.ActiveOrderId, Number(selectedProject))
        props.ActiveOrderId = 0
        props.ActiveServiceName = "Отсутствует"
        const purchasedServices = await GetPurchasedServices(props.AccountId)
        SetPurchardServices(purchasedServices.UpdatedItem)
    }

    return (
        <>
            <h4 className="purpleTextHead">Активный тарифный план: {props.ActiveServiceName}</h4>

            <div className="formWrapper">
                <h5 className="purpleTextHeadSmall">Внести обеспечительный платёж</h5>
                <div className="inputWrapper">
                    <TextInput onChange={handleLotNumChange} required={true} placeholder="№ лота" name="lotNum" />
                    <button type="button" onClick={handleMakingDeposit} className="defaultPinkButton">
                        Внести задаток
                    </button>
                </div>
            </div>

            <h4 className="purpleTextHead">Внесённые обеспечительные платежи:</h4>

            <div className="formWrapper">
                <h5 className="purpleTextHeadSmall">Оплатить услугу</h5>
                <div className="inputWrapper">
                    {servicesList &&
                        <div className={isOpen2 ? "customSelect2 open" : "customSelect2"}>
                            <div className="activeOption" onClick={() => { setIsOpen2(!isOpen2) }}>
                                {activeOption2 ? activeOption2 : 'Выбрать тариф'}
                                <svg style={{ transform: isOpen ? 'rotate(180deg)' : '' }} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                    <path d="M5.19205 5.76856L8.90654 1.31117C9.34076 0.790108 8.97024 -0.000977755 8.29197 -0.000977755L1.70803 -0.000977755C1.02976 -0.000977755 0.659236 0.790107 1.09345 1.31117L4.80794 5.76856C4.90789 5.8885 5.09211 5.8885 5.19205 5.76856Z" fill="#170021" />
                                </svg>
                            </div>
                            <div className="options">
                                {servicesList.map((option) =>
                                    <p key={option.Id} className={option.Value} onClick={() => {
                                        setActiveOption2(option.Name)
                                        handleSelectChange(option)
                                        setIsOpen2(false)
                                    }}>{option.Name} - {option.Price}</p>
                                )}
                            </div>
                        </div>
                    }
                    

                    <button type="button" onClick={makeTarifPayment} className="defaultPinkButton">
                        Сформировать счет
                    </button>


                </div>
            </div>


            <div className="formWrapper">
                <h5 className="purpleTextHeadSmall">Перенести активную услугу на другой проект:</h5>
                <div className="inputWrapper">
                    <div className={isOpen ? "customSelect2 open" : "customSelect2"}>
                        <div className="activeOption" onClick={() => { setIsOpen(!isOpen) }}>
                            {activeOption ? activeOption : 'Митра главная'}
                            <svg style={{ transform: isOpen ? 'rotate(180deg)' : '' }} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                <path d="M5.19205 5.76856L8.90654 1.31117C9.34076 0.790108 8.97024 -0.000977755 8.29197 -0.000977755L1.70803 -0.000977755C1.02976 -0.000977755 0.659236 0.790107 1.09345 1.31117L4.80794 5.76856C4.90789 5.8885 5.09211 5.8885 5.19205 5.76856Z" fill="#170021" />
                            </svg>
                        </div>
                        <div className="options">
                            {Object.keys(props.ProjectsDictionary).map((key) =>
                                <p className={key} onClick={() => {
                                    setActiveOption(props.ProjectsDictionary[key])
                                    handleSelectProject(key)
                                    setIsOpen(false)
                                }}>{props.ProjectsDictionary[key]}</p>
                            )}
                        </div>
                    </div>

                    <button type="button" onClick={handleSwitchServiceProject} className="defaultPinkButton">
                        Выполнить перенос
                    </button>
                </div>
            </div>


            { console.log(props.ActiveOrderId)}
        </>
    )
}

export default MyBalance


    //< p className = "font-weight-500 roboto-font pt-2 fw-bold" > Активный тарифный план: { props.ActiveServiceName }</p >
    //        <form>
    //            <p className="font-weight-500 roboto-font pt-2 fw-bold">Внести обеспечительный платёж</p>
    //            <div className="row">
    //                <div className="col-8">
    //                    <TextInput onChange={handleLotNumChange} required={true} placeholder="№ лота" name="lotNum" />
    //                </div>
    //                <div className="col-4">
    //                    <button type="button" onClick={handleMakingDeposit} className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2 mb-3">
    //                        Внести задаток
    //                    </button>
    //                </div>
    //            </div>
    //            <p className="font-weight-500 roboto-font pt-2 fw-bold">Внесённые обеспечительные платежи:</p>
    //            <div style={{ maxHeight: "100px", overflowY: "auto" }}>
    //                <table>
    //                    {purchasedLot.map((lot) => (
    //                        <tr onClick={() => RedirectToTradeLot(lot.LotId)} key={lot.LotId} style={{ color: "#1c2c9c" }}
    //                            className="font-weight-500 roboto-font pt-2 fw-bold col gutter-col-10 cursor-pointer"
    //                            onMouseEnter={(e) => e.currentTarget.style.textDecoration = "underline"}
    //                            onMouseLeave={(e) => e.currentTarget.style.textDecoration = "none"}                            >
    //                            <td style={{ paddingRight: "15px" }}>Лот №{lot.LotId}</td>
    //                            <td style={{ paddingRight: "15px" }}>{lot.OrderStatus}</td>
    //                            <td style={{ paddingRight: "15px" }}>{lot.Price} руб.</td>
    //                        </tr>
    //                    ))}
    //                </table>
    //            </div>
    //        </form>
    //        <form>
    //            <p className="font-weight-500 roboto-font pt-2 fw-bold">Оплатить услугу</p>
    //            <div className="row">
    //                <div className="col-8 d-flex align-items-center">
    //                    <select className="form-control" name="serviceId" onChange={handleSelectChange}>{
    //                        props.ListServices.map((x) =>
    //                            <option key={x.Value} value={x.Value}>{x.Text}</option>)
                                
    //                    }</select>
                        
    //                </div>
    //                <div className="col-4 d-flex align-items-center">
    //                    <button onClick={handleAddPurchasedServices} type="button" className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2">Сформировать счет</button>
    //                </div>
    //            </div>
    //            <p className="font-weight-500 roboto-font pt-2 fw-bold">Купленные услуги:</p>
    //            <div style={{ maxHeight: "100px", overflowY: "auto" }}>
    //            <table>
    //                {purchasedServices.map((service) => (
    //                    <tr key={service.Name} style={{ color: "#1c2c9c" }} className="font-weight-500 roboto-font pt-2 fw-bold col gutter-col-10">
    //                        <td style={{ paddingRight: "15px" }}>{service.Name}</td>
    //                        <td style={{ paddingRight: "15px" }}>{service.OrderStatus}</td>
    //                        <td style={{ paddingRight: "15px" }}>{service.Price} руб.</td>
    //                    </tr>
    //                ))}
    //                </table>
    //            </div>
    //        </form>
    //        <br/>
    //        <form>
    //            <p className="font-weight-500 roboto-font pt-2 fw-bold">Перенести активную услугу на другой проект:</p>
    //            <div className="row">
    //                <div className="col-8 d-flex align-items-center">
    //                    <select className="form-control" name="projectId" onChange={handleSelectProject}>{
    //                        Object.keys(props.ProjectsDictionary).map((key) =>
    //                            <option key={key} value={key}>{props.ProjectsDictionary[key]}</option>)

    //                    }</select>

    //                </div>
    //                <div className="col-4 d-flex align-items-center">
    //                    <button onClick={handleSwitchServiceProject}  type="button" className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2">Выполнить перенос</button>
    //                </div>
    //            </div>
    //        </form>