import React, { useState, useEffect } from "react";
import fetcher, { fetcherGET } from "../Common/Fetch";
import { CardLotModel } from "../Catalog/types";
import CardLot from "../Catalog/CardLot";
import CardLotCloseSales from "../Catalog/CardLotCloseSales";


interface IPropsType {
    companyId: number
}

function UserLots(props: IPropsType) {

    const [lots, setLots] = useState<Array<CardLotModel>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getLots();
    }, [])

    const getLots = () => {
        console.log('getLots')
        fetcherGET(`/TradeLotApi/GetLotsByCompanyId?companyId=${props.companyId}`, (result) => {
            if (result.Success == true) {
                console.log(result.UpdatedItem)
                let slice = result.UpdatedItem.slice(0, 3);
                setLots(slice.map(item => ({
                    ...item,
                    Price: item.BasePrice,
                    AuctionType: item.TradeTypeDescription,
                    StatusName: item.LotTradeStateDescription,
                    LotId: item.Id,
                    LotName: item.Name
                })));
                setIsLoading(false);
            }
            else {
                console.log('error!');
            }
        });
    }

    const onIsFavoriteToggle = () => {

    }

    return (
        <div className="otherLots">
            <h4 className="px30PinkHead">Работы автора</h4>
            <div className="lotsList">
                {isLoading ?
                    (
                        <img src="/Content/images/loading_sm.gif" style={{ width: "100px" }} />
                    ) : (
                        lots.length > 0 ? lots.map(lot => (
                            <div key={lot.LotId} className="sub-category-card promoLot">
                                {lot.IsClosedTrade ?
                                    <CardLotCloseSales lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                    :
                                    <CardLot lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                }
                            </div>
                        )) : <p className="errorText">Произошла ошибка..</p>
                    )
                }
            </div>

            <a className="defaultPinkBorderButton" href="/Category/Details">Показать все лоты</a>
        </div>
    )
}

export default UserLots;