import React, { useState, useEffect } from "react";
import AboutLot from "./AboutLot";
import AboutSaler from "./AboutSaler";
import AboutTrade from "./AboutTrade";
import LotBets from "./LotBets";
import LotContacts from "./LotContacts";
import fetcher from "../../Common/Fetch";

import { ILotModel, IDocumentModel, TradeStates } from "../../Common/types";
import { IAboutTrade } from "./LotPage";

interface IPropsType {
    lotDetails: ILotModel,
    aboutTrade: IAboutTrade,
    documents: IDocumentModel,
    lotId: number
}


function LotDetails(props: IPropsType) {
    const [activeDetail, setActiveDetail] = useState('AboutLot');

    return (
        <div className="lotDetails">
            <div className="menuButtons">
                <h4
                    className={activeDetail == 'AboutLot' ? 'active' : ''}
                    onClick={() => { setActiveDetail('AboutLot') }}>
                    Основные <br></br> характеристики
                </h4>
                <h4 className={activeDetail == 'AboutTrade' ? 'active' : ''}
                    onClick={() => { setActiveDetail('AboutTrade') }}>
                    Дополнительная <br></br> информация
                </h4>
                <h4
                    className={activeDetail == 'AboutSaler' ? 'active' : ''}
                    onClick={() => { setActiveDetail('AboutSaler') }}>
                    Об авторе
                </h4>
                <h4 className={activeDetail == 'LotBets' ? 'active' : ''}
                    onClick={() => { setActiveDetail('LotBets') }}>
                    Ставки
                </h4>
                <h4 className={activeDetail == 'LotContacts' ? 'active' : ''}
                    onClick={() => { setActiveDetail('LotContacts') }}>
                    Контакты
                </h4>
            </div>
            {activeDetail === 'AboutLot' &&
                <AboutLot
                    lotFilters={props.lotDetails.Filters}
                    documents={props.documents}
                />}
            {activeDetail === 'AboutSaler' &&
                <AboutSaler
                    sellerInfo={props.lotDetails.Seller}
                    lotDetails={props.lotDetails}
                    lotId={props.lotId}
                />}
            {activeDetail === 'AboutTrade' &&
                <AboutTrade
                    aboutTrade={props.aboutTrade}
                    allowProcessing={props.lotDetails.AllowProcessApplications}
                    lotDescription={props.lotDetails.Information}
                    lotId={props.lotDetails.Id}
                    allowFinish={props.lotDetails.LotTradeStateId == TradeStates.Traded}
                />}
            {activeDetail === 'LotBets' && <LotBets key={props.lotDetails.LotPriceHistoryModel.length} lotDetails={props.lotDetails} />}
            {activeDetail === 'LotContacts' && <LotContacts />}
        </div>
    )
}


export default LotDetails;