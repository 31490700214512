import React, { MouseEventHandler, useState, useEffect } from "react";
import $ from "jquery";
import { DEFAULT_LOT_IMAGE, LOT_DETAILS_URL } from "../settings";
import { Nullable, IListLot } from "../Common/types";
import ToggleLotIsFavorite from "../Common/ToggleLotIsFavorite/ToggleLotIsFavorite";
import AccountInfo from "../Profile/AccountInfo";
import { CardLotModel } from "./types"
import { ApplicationDetails } from "../TradeApplication/components/ApplicationDetails";
import { ApplicationAcceptance } from "../TradeApplication/components/ApplicationAcceptance";

interface IPropsType {
    lot: CardLotModel,
    onIsFavoriteToggle?: Function,
    showApp?: boolean,
    isAcceptance: boolean
}

function CardLot({ lot, showApp, isAcceptance }: IPropsType) {
    let url = lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId;

    useEffect(() => {
        console.log(lot.ImagePath)
    }, [lot])

    return (

           <div className="cardContent">
            <div className="cardImage">
                <a href={url} data-attribute-URL_={url}>
                    {(lot.ImagePath && lot.ImagePath.length > 0) ?
                        <img className="img-100w" key={lot.ImageId} src={lot.FullImagePath} alt={lot.ImageName} />
                    :
                        <img src="../../main-images/defaultLotImage.png" className="img-100w" alt="Картинка по умолчанию" />
                    }

                </a>
                {AccountInfo.Id &&
                    <ToggleLotIsFavorite lotId={lot.LotId} curUserFavorite={lot.IsInFavorites} showText={false} type="lot" />
                }
                           
            </div>
            <div className="cardInfo">
                <a className="cardName" href={url} data-attribute-URL_={url}>
                    {lot.LotName}
                </a>

                <div className="cardDescription">

                        <div className="cartType cardDescriptionItem">
                            <h5>Тип торга:</h5>
                            <span>{lot.AuctionType}</span>
                        </div>
                        <div className="cardTypeSale cardDescriptionItem">
                            <h5>Статус:</h5>
                            <span>{lot.StatusName}</span>
                        </div>


                        <div className="cardNumber cardDescriptionItem">
                            <h5>Номер лота:</h5>
                            <span>{lot.LotId}</span>
                        </div>
                        <div className="cardPrice cardDescriptionItem">
                            <h5>Текущая цена:</h5>
                            <span>{lot.Price.toLocaleString('ru')} руб.</span>
                        </div>

                </div>
                {isAcceptance &&
                    <ApplicationAcceptance
                        lotId={lot.LotId}
                    />
                }
                <div className="cardActivity">
                    <div className="views cardActivityItem">
                        <img src="../../../main-images/views.svg" alt="views"></img>
                        <span>152</span>
                    </div>
                    <div className="viewNow cardActivityItem">
                        <img src="../../../main-images/viewNow.svg" alt="view now"></img>
                        <span>10</span>
                    </div>
                    <div className="applications cardActivityItem">
                        <img src="../../../main-images/applications.svg" alt="applications"></img>
                        <span>6 Заявок</span>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default CardLot;