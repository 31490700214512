import React, { useState, useEffect } from "react";
import fetcher from "../../Common/Fetch";
import { ILotModel, LotPriceHistoryModel } from "../../Common/types";
import * as signalR from '@microsoft/signalr';
import moment from 'moment';

interface IPropsType {
    lotDetails: ILotModel,
    lotId: number
}

function LotBets(props: IPropsType) {

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`/betting?lotId=${props.lotId}`)
            .build();

        connection.on('NewBet', (obj) => {
            if (obj.Price && obj.BettorName) {
                const newPrice = parseFloat(Number(obj.Price).toFixed(2));
                const formattedBet = {
                    price: newPrice,
                    bettorName: obj.BettorName,
                    dateCreated: moment(obj.DateCreated).format('DD.MM.YYYY'),
                    timeCreated: moment(obj.DateCreated).format('HH.mm.ss'),
                    minimumBet: obj.MinimumBet ? parseFloat(Number(obj.MinimumBet).toFixed(2)) : null,
                    totalBets: obj.TotalBets,
                };

                console.log(formattedBet);
            }
        });

        connection
            .start()
            .then(() => console.log('Подключение установлено'))
            .catch((err) => console.error('Ошибка подключения:', err));

        return () => {
            connection.stop().then(() => console.log('Подключение остановлено'));
        };
    }, [props.lotId]);

    const [bets, setBets] = useState<Array<LotPriceHistoryModel>>(props.lotDetails.LotPriceHistoryModel);

    //const [bets, setBets] = useState<Array<LotPriceHistoryModel>>([
    //    {BettorName: 'какое-то длинное имя', Price: 40000000, DateCreated: '23.07.2022'}
    //]);
    useEffect(() => {
        setBets(props.lotDetails.LotPriceHistoryModel);
    }, [props.lotDetails.LotPriceHistoryModel]);

    const formattingDate = (date) => {
        const newDate = new Date(date);

        const time = newDate.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        const dateString = newDate.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'long',
            weekday: 'short'
        });

        const formatted = `${dateString} ${time}`;

        return formatted;
    }


    return (
        <div className="lotBets detailsWrapper">
            <table>
                <thead>
                    <tr>
                        <th className="defaultDataName">Пользователь</th>
                        <th className="defaultDataName">Цена</th>
                        <th className="defaultDataName">Дата</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {bets.length != 0 ? (
                        bets.map((bet, index) => {
                            return (
                                <tr key={index}>
                                    <td className="defaultDataValue">{bet.BettorName}</td>
                                    <td className="defaultDataValue">{bet.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽</td>
                                    <td className="defaultDataValue">{formattingDate(bet.DateCreated)}</td>
                                </tr>
                            )
                        })
                    ): (
                        <p className="errorText">Ставок пока нет. Вы можете стать первым!</p>
                    )}
                </tbody>
            </table>

            {/*<button className="defaultPinkBorderButton">Показать все ставки</button>*/}
        </div>
    )
}


export default LotBets;