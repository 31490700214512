import React, { useState, useEffect } from "react";
import { fetcherPOSTJSON } from "../../Common/Fetch";
import { FilterModel } from "./types";
import { CardLotModel } from "../../Catalog/types";
import CardLot from "../../Catalog/CardLot";
import CardLotCloseSales from "../../Catalog/CardLotCloseSales";

function OtherLots() {

    const [lots, setLots] = useState<Array<CardLotModel>>([]);
    const [filters, setFilters] = useState<FilterModel>(
        {
            CategoryId: 0,
            CurrentPage: 1,
            CountLotsOnPage: 2,
            AuctionType: 0,
            SalesType: 0,
            TimeSearch: 4,
            StartDate: null,
            EndDate: null,
            StartPrice: 0,
            EndPrice: 0,
            TextSearch: "",
            dateInputLocale: null
        }
    );
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        var json = JSON.stringify(filters);
        fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
            if (result.Success == true) {
                console.log(result.lots)
                setLots(result.lots);
                setIsLoading(false);
            }
            else {
                console.log('error!');
            }
        });
    }, [filters])

    const onIsFavoriteToggle = () => {

    }

    return (
        <div className="otherLots">
            <h4 className="px30PinkHead">Другие лоты</h4>
            <div className="lotsList">
                {isLoading ?
                    (
                        <img src="/Content/images/loading_sm.gif" style={{ width: "100px" }} />
                    ) : (
                        lots.length > 0 ? lots.map(lot => (
                            <div key={lot.LotId} className="sub-category-card promoLot">
                                {lot.IsClosedTrade ?
                                    <CardLotCloseSales lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                    :
                                    <CardLot lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                }
                            </div>
                        )) : <p className="errorText">Произошла ошибка..</p>
                    )
                }
            </div>

            <a className="defaultPinkBorderButton" href="/Category/Details">Показать все лоты</a>
        </div>
    )
}

export default OtherLots;