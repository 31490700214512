import React, { useState, useEffect } from "react";
import LotGallery from "./LotGallery";
import LotActionMenu from "./LotActionMenu";
import ContactPopup from "../../Shared/ContactPopup";
import { ILotHeadInfo } from "./LotPage";
import { ILotModel } from "../../Common/types";
import AgentHelper from "../../CategoryLotsList/AgentHelper/AgentHelper"

interface IPropsType {
    lotHeadInfo: ILotHeadInfo,
    pictures: Array<Object>,
    lotId: number,
    lotDetails: ILotModel,
    theme: string
}

function LotHead(props: IPropsType) {

    const [cadastr, setCadastr] = useState();

    useEffect(() => {
        if (props.lotDetails.Filters['Основные характеристики'] && props.lotDetails.Filters['Основные характеристики'].find(item => item.FilterName === 'Кадастровый номер')) {
            setCadastr(props.lotDetails.Filters['Основные характеристики'].find(item => item.FilterName === 'Кадастровый номер').Value)
        }
    }, [props.lotDetails])

    return (
        <div className="lotHead">
            <h1>{props.lotHeadInfo.Name}
                {cadastr &&
                    <span>к/н {cadastr}</span>   
                }
            </h1>
            <div className="middle">
                <LotGallery pictures={props.pictures} lotId={props.lotId} lotDefails={props.lotDetails} />
                <LotActionMenu lotHeadInfo={props.lotHeadInfo} lotId={props.lotId} lotDetails={props.lotDetails} theme={props.theme} />
            </div>
            <div className="contactButtons">
                <a className="defaultPinkBorderButton containerForDefaultPopup">
                    <div className="popupRender">
                        <AgentHelper
                            addition={'Заявка со страницы лота - Помощь агента'}
                        />
                    </div>
                    Помощь агента
                </a>
                <a className="defaultPinkBorderButton containerForDefaultPopup">
                    <div className="popupRender">
                        <AgentHelper
                            addition={'Заявка со страницы лота - Заказ онлайн показа'}
                            poputTitle={'Заказать онлайн показ'}
                        />
                    </div>
                    Заказать онлайн показ
                </a>
            </div>
        </div>
    )
}


export default LotHead;