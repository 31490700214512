import React, { useState, useEffect } from "react";
import { fetcherPOSTJSON } from "../Common/Fetch";
import { FilterModel } from "../Catalog/types";
import { CardLotModel } from "../Catalog/types";
import SCcloseRowItem from "../Common/SCcloseRowItem/SCcloseRowItem";

interface NewsItem {
    Id: number,
    Title: string,
    Summary: string,
    SeoUrl: string,
    Content: string,
    PublicationDate: string,
    ImageUrl: string,
    Image: any
}

function SmallPromoLots() {

    const [lots, setLots] = useState<Array<CardLotModel>>();
    const [isCloseOpen, setIsCloseOpen] = useState(false);

    const [filters, setFilters] = useState<FilterModel>(
        {
            CategoryId: 0,
            CurrentPage: 1,
            CountLotsOnPage: 4,
            AuctionType: 0,
            SalesType: 0,
            TimeSearch: 4,
            StartDate: null,
            EndDate: null,
            StartPrice: 0,
            EndPrice: 0,
            TextSearch: "",
            dateInputLocale: null
        }
    );

    useEffect(() => {
        getListLots();
    }, []);

    const getListLots = () => {
        var json = JSON.stringify(filters);
        fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
            if (result.Success == true) {
                console.log(result.lots)
                setLots(result.lots);
            }
            else {
                console.log('error!');
            }
        });
    }

    const closePopup = () => {
        setIsCloseOpen(false);
    }

    const openPopup = () => {
        setIsCloseOpen(true);
    }

    return (
        <>
            {lots &&
                lots.map(lot => {
                    return (
                        <div key={lot.LotId} className="promoLot" onClick={() => {lot.IsClosedTrade ? setIsCloseOpen(true) : null }} >
                            <div className="bgImage">
                                <img src={lot.FullImagePath ? lot.FullImagePath : "../../main-images/defaultLotImage.png"} />
                            </div>
                            <a href={lot.SeoUrl}>
                                <h4>{lot.LotName}</h4>
                                <p className="details">
                                    Подробнее
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                        <path d="M0.46967 9.96967C0.176777 10.2626 0.176777 10.7374 0.46967 11.0303C0.762563 11.3232 1.23744 11.3232 1.53033 11.0303L0.46967 9.96967ZM10.75 1.5C10.75 1.08579 10.4142 0.75 10 0.75L3.25 0.75C2.83579 0.75 2.5 1.08579 2.5 1.5C2.5 1.91421 2.83579 2.25 3.25 2.25H9.25V8.25C9.25 8.66421 9.58579 9 10 9C10.4142 9 10.75 8.66421 10.75 8.25L10.75 1.5ZM1.53033 11.0303L10.5303 2.03033L9.46967 0.96967L0.46967 9.96967L1.53033 11.0303Z" fill="white" />
                                    </svg>
                                </p>
                            </a>
                        </div>
                    )
                })    
            }

            {isCloseOpen &&
                <SCcloseRowItem
                    closePopup={closePopup}
                    userName=""
                    openPopup={openPopup}
                    onClose={closePopup}
                />
            }

        </>
    );
}

export default SmallPromoLots;